import { Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';

import PageContent from '@root/components/v2/layout/PageContent';

import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
import './risk.scss';

function RiskSkeleton() {
  const tilesData = [
    'Network',

    'Encryption',

    'Data Security',

    'Exposed Secrets',

    'Identity',

    'High Availability',
    'Logging & Monitoring',

    'Vulnerabilities',

    'Lateral Movement',
  ];
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />
      <Grid className="risk_new">
        <Grid className="top_card m-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid className="">
            <Skeleton height={20} width="112px" style={{ transform: 'none', marginBottom: '20px' }} />

            <Skeleton height={20} width="112px" style={{ transform: 'none' }} />
          </Grid>
          <Grid>
            <span className="border-card"></span>
          </Grid>
          {/* card 2 */}
          <Grid className="">
            <Skeleton height={20} width="300px" style={{ transform: 'none', marginBottom: '20px' }} />
            <Skeleton height={20} width="300px" style={{ transform: 'none' }} />
          </Grid>
          <Grid sx={{ width: '2px' }}>
            <span className="border-card"></span>
          </Grid>
          {/* card 3 */}
          <Grid className="">
            <Skeleton height={20} width="300px" style={{ transform: 'none', marginBottom: '20px' }} />
            <Skeleton height={20} width="300px" style={{ transform: 'none' }} />
          </Grid>
          <Grid>
            <span className="border-card"></span>
          </Grid>
          {/* card 4 */}
          <Grid className="">
            <Skeleton height={20} width="347px" style={{ transform: 'none', marginBottom: '20px' }} />
            <Skeleton height={20} width="347px" style={{ transform: 'none' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: '15px', border: '1px solid #CBD5E0', borderRadius: '5px', backgroundColor: '#ffff' }}>
        <Grid>
          <Grid sx={{ borderBottom: '1px solid #CBD5E0' }}>
            <p
              className="m-0"
              style={{
                fontFamily: 'Manrope',
                fontSize: '18px',
                color: '#1A202C',
                padding: '15px',
                fontWeight: 700,
              }}>
              {' '}
              Risk Over Time
            </p>
          </Grid>
          <Grid sx={{ height: '320px', padding: '23px 30px 58px 30px' }}>
            <Skeleton height={210} style={{ transform: 'none' }} />
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton height={20} width="347px" style={{ transform: 'none', marginTop: '15px' }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ display: 'flex' }}>
          {tilesData.map((name, index) => {
            return (
              <Grid
                item
                xs={4}
                style={index == 2 || index == 5 || index == 8 ? {} : { borderRight: '1px solid #CBD5E0' }}
                sx={{
                  borderTop: '1px solid #CBD5E0',
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0px 18px 20px 0px rgba(93, 106, 131, 0.20)',
                  },
                }}>
                <Grid sx={{ marginLeft: '35px', my: '15px', display: 'flex' }}>
                  <Skeleton height={20} width="150px" style={{ transform: 'none' }} />
                </Grid>
                <Grid
                  sx={{
                    marginLeft: '35px',
                    my: '22px',
                    marginRight: '61px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Grid>
                    <Grid sx={{ display: 'flex', mb: '12px' }}>
                      <Skeleton height={20} width="70px" style={{ transform: 'none' }} />
                    </Grid>
                    <Grid sx={{ display: 'flex', marginRight: '7rem' }}>
                      <Skeleton height={20} width="70px" style={{ transform: 'none' }} />
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid sx={{ width: '200px', height: '55px', mx: '12px' }}>
                      <Skeleton height={55} width="100%" style={{ transform: 'none', flexGrow: 1 }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default RiskSkeleton;

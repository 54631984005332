import { Grid, Skeleton } from '@mui/material';

import TableSkeleton from '@root/components/table/skeleton';
import BasicContentBox from '@root/components/v2/content/BasicContentBox';
import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

function EventOverviewSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />

      <Grid container spacing={'15px'} style={{ marginBottom: '15px' }}>
        <Grid item xs={6}>
          <BasicContentBox title="Events" style={{ height: '405px' }}>
            <Skeleton height={361} style={{ transform: 'none' }} />
          </BasicContentBox>
        </Grid>
        <Grid item xs={6}>
          <BasicContentBox
            title="Top 10 Critical Threat & Anomaly Events"
            style={{ height: '405px' }}
            innerStyles={{ overflow: 'hidden' }}>
            <div style={{ height: 'calc(100% + 10px)' }}>
              <TableSkeleton COLUMNS_COUNT={5} ROWS_COUNT={7} hideFooter noBorder />
            </div>
          </BasicContentBox>
        </Grid>
        <Grid item xs={6}>
          <BasicContentBox
            title="Events Over Time"
            style={{ height: '350px' }}
            innerStyles={{ overflow: 'hidden' }}
            // rightSideContent={<EventsDropdown eventType={eventType} setEventType={setEventType} />}
          >
            <Grid>
              <Skeleton height={240} style={{ transform: 'none' }} sx={{ margin: '15px' }} />
            </Grid>
            <Grid sx={{ display: 'flex', margin: '15px 0px', justifyContent: 'center' }}>
              <Skeleton height={20} width={100} style={{ transform: 'none' }} sx={{ marginRight: '15px' }} />
              <Skeleton height={20} width={100} style={{ transform: 'none' }} />
            </Grid>
          </BasicContentBox>
        </Grid>
        <Grid item xs={6}>
          <BasicContentBox
            title="Most Critical Vulnerabilities"
            style={{ height: '350px' }}
            innerStyles={{ overflow: 'hidden' }}
            // rightSideContent={
            //   <ExportIcon
            //     style={{ cursor: 'pointer', padding: '8px 15px' }}
            //     onClick={() => handleVulnerabilitiesRedirection(mostCriticalVulnerabilities.data)}
            //   />
            // }
          >
            <div style={{ height: 'calc(100% + 10px)' }}>
              <TableSkeleton COLUMNS_COUNT={5} ROWS_COUNT={7} hideFooter noBorder />
            </div>
          </BasicContentBox>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default EventOverviewSkleton;

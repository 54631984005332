import React from 'react';
import { Grid, Skeleton, Box } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';

import PageContent from '@root/components/v2/layout/PageContent';
import './style.scss';

import BasicContentBox from '@root/components/v2/content/BasicContentBox';
// import search_icon from '@root/assets/svg/icons/new/search_icon.svg';
// import redirection from '@root/assets/svg/icons/new/rederection.svg';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
function DataSecuritySkeleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton isRegionFilter={true} />
      <CloudProviderBarSkeleton />
      <Grid
        sx={{
          display: 'flex',
          fontFamily: 'Manrope',
          flexDirection: 'column',
          gap: '15px',
        }}>
        <Grid container direction="row" spacing={1.7}>
          <Grid container item direction="column" xs={6} spacing={1.7}>
            <Grid item>
              <BasicContentBox
                title="Asset Overview"
                style={{
                  height: '166px',

                  //   width:'100%',
                  //   padding: '15px',
                  //   display: 'flex',
                  //   justifyContent: 'space-between',
                  //   alignItems: 'center',
                }}>
                <Box
                  sx={{
                    width: '100%',
                    padding: '15px',
                    // border: '1px solid red',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    //   gap: '20px',
                  }}>
                  {Array.from(new Array(4)).map(i => (
                    <Box
                      key={i}
                      sx={{
                        // width: '108px',
                        // border: '1px solid blue',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        height: '100%',
                        borderRadius: '8px',
                      }}>
                      <Skeleton variant="rectangular" width={60} height={60} />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          // marginTop: '40px',
                          gap: '8px',
                          flexDirection: 'column',
                        }}>
                        <Skeleton variant="rectangular" width={60} height={10} />
                        <Skeleton variant="rectangular" width={60} height={10} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </BasicContentBox>
            </Grid>
            <Grid item>
              <BasicContentBox title="Data Distribution" style={{ height: '216px' }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    padding: '15px',
                    overflow: 'hidden !important',
                  }}>
                  <Box
                    sx={{
                      // border: '1px solid red',
                      height: '90%',
                      display: 'flex',
                      flexDirection: 'column',
                      // alignItems: 'center',
                      gap: '24px',
                      // paddingTop: '16px',
                      paddingLeft: '8px',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center',
                        gap: '8px',
                      }}>
                      <Skeleton variant="rectangular" height={30} width={180} />
                      <Skeleton variant="rectangular" height={30} width={100} />
                    </Box>

                    <Skeleton variant="rectangular" height={25} width={'95%'} />
                  </Box>
                  <Box
                    sx={{
                      // border: '1px solid blue',
                      height: '20%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                    }}>
                    {Array.from(new Array(3)).map(idx => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                        }}>
                        <Skeleton variant="circular" height={12} width={12} />

                        <Skeleton variant="rectangular" height={12} width={70} />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </BasicContentBox>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item style={{ height: '100%' }}>
              <BasicContentBox title="Sensitive Data" style={{ height: '100%', overflow: 'none !important' }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    padding: '15px',
                    zIndex: '999',
                    // border: '1px solid red',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                  }}>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      // border: '1px solid red'
                    }}>
                    <Box
                      sx={{
                        height: '35%',
                        // border: '1px solid red',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}>
                      <Skeleton variant="rectangular" height={35} width={'40%'} />
                      <Skeleton variant="rectangular" height={60} width={80} />
                    </Box>
                    <Box
                      sx={{
                        height: '70%',
                        // border: '1px solid green',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '16px',
                      }}>
                      {Array.from(new Array(4)).map(idx => (
                        <Box key={idx}>
                          <Skeleton variant="circular" height={120} width={120} />
                        </Box>
                      ))}
                    </Box>
                    <Box></Box>
                  </Box>
                </Box>
              </BasicContentBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1.7}>
          <Grid item xs={6}>
            <BasicContentBox
              title="Alerts"
              style={{
                height: '206px',
                //   width:'100%',
                //   padding: '15px',
                //   display: 'flex',
                //   justifyContent: 'space-between',
                //   alignItems: 'center',
              }}>
              <Box
                sx={{
                  width: '100%',
                  padding: '15px',
                  // border: '1px solid red',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  //   gap: '20px',
                }}>
                {Array.from(new Array(4)).map(i => (
                  <Box
                    key={i}
                    sx={{
                      // width: '108px',
                      // border: '1px solid blue',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      height: '100%',
                      borderRadius: '8px',
                    }}>
                    <Skeleton variant="rectangular" width={60} height={60} />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        // marginTop: '40px',
                        // width:'100%',
                        gap: '8px',
                        flexDirection: 'column',
                      }}>
                      <Skeleton variant="rectangular" width={60} height={10} />
                      <Skeleton variant="rectangular" width={60} height={10} />
                    </Box>
                  </Box>
                ))}
              </Box>
            </BasicContentBox>
          </Grid>
          <Grid item xs={6}>
            <BasicContentBox
              title="Live Secrets"
              style={{
                height: '206px',
                //   width:'100%',
                //   padding: '15px',
                //   display: 'flex',
                //   justifyContent: 'space-between',
                //   alignItems: 'center',
                // overflowY: 'none !important'
              }}>
              <Box
                sx={{
                  // border: '1px solid red',
                  height: '85%',
                  display: 'flex',
                  flexDirection: 'column',
                  // alignItems: 'center',
                  gap: '16px',
                  paddingTop: '16px',
                  paddingLeft: '32px',
                }}>
                <Skeleton variant="rectangular" height={25} width={'95%'} />
                <Skeleton variant="rectangular" height={25} width={'95%'} />
                <Skeleton variant="rectangular" height={25} width={'95%'} />
              </Box>
              <Box
                sx={{
                  // border: '1px solid blue',
                  // height: '20%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                }}>
                {Array.from(new Array(3)).map(idx => (
                  <Box
                    key={idx}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}>
                    <Skeleton variant="circular" height={12} width={12} />

                    <Skeleton variant="rectangular" height={12} width={70} />
                  </Box>
                ))}
              </Box>
              {/* </Box> */}
            </BasicContentBox>
          </Grid>
        </Grid>
        <Grid container spacing={1.7}>
          <Grid item xs={6}>
            <BasicContentBox
              title="Active Threats"
              //   subtitle="Total GB Scanned"
              style={{ height: '480px', overflow: 'hidden' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  //   border: '1px solid red',
                  padding: '15px',
                }}>
                {Array.from(new Array(5)).map(idx => (
                  <Box
                    key={idx}
                    sx={{
                      width: '100%',
                      height: '20%',
                      // border: '1px solid red',
                    }}>
                    <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                  </Box>
                ))}
              </Box>
            </BasicContentBox>
          </Grid>
          <Grid item xs={6}>
            <BasicContentBox title="Attack Path" subtitle="Attack Path" style={{ height: '480px', overflow: 'hidden' }}>
              <Box
                // ref={containerRef}
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflowX: 'hidden',
                }}>
                <Box
                  sx={{
                    height: '70%',
                    // border: '1px solid red',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Skeleton variant="circular" height={275} width={275} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // border: '1px solid red',
                    gap: '8px',
                    height: '30%',
                    padding: '15px',
                  }}>
                  <Skeleton width="25%" height="120px" sx={{ marginRight: '10px' }} />
                  <Skeleton width="25%" height="120px" sx={{ marginRight: '10px' }} />
                  <Skeleton width="25%" height="120px" sx={{ marginRight: '10px' }} />
                  <Skeleton width="25%" height="120px" />
                </Box>
              </Box>
            </BasicContentBox>
          </Grid>
        </Grid>
        <Grid container spacing={1.7}>
          <Grid item xs={6}>
            <BasicContentBox
              title="PII Files"
              //   subtitle="Total GB Scanned"
              style={{ height: '380px', overflow: 'hidden' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '15px',
                  gap: '8px',
                }}>
                {Array.from(new Array(9)).map(i => (
                  <Skeleton key={i} variant="rectangular" height={30} width={'100%'} />
                ))}
              </Box>
            </BasicContentBox>
          </Grid>
          <Grid item xs={6}>
            <BasicContentBox
              title="Top Active Actors"
              containerClassName={'border-box'}
              paddingMode={'content'}
              // titleContainerStyle={{
              //   backgroundColor: '#F6F8FB',
              //   borderRadius: '5px 5px 0px 0px',
              // }}
              titleSize="medium"
              infoMsg={<>Top Active identities for Data Assets</>}
              style={{ height: '380px' }}>
              <Box height={'100%'}>
                {/* <Loader /> */}
                <Box display="flex" justifyContent="space-between" padding="10px">
                  <Skeleton width="200px" height="25px" />
                  <Skeleton width="300px" height="25px " />
                </Box>

                <Box display="flex" justifyContent="space-between" width="100%" height="80%" padding="10px">
                  <Box display="flex" flexDirection="column" boxSizing="border-box" height="100%">
                    {Array.from(new Array(10)).map((i, index) => (
                      <Skeleton key={index} width="200px" height="25px" />
                    ))}
                  </Box>
                  <Box display="flex" flexDirection="column" boxSizing="border-box" height="100%">
                    {Array.from(new Array(10)).map((i, index) => (
                      <Skeleton key={index} width="300px" height="25px" />
                    ))}
                  </Box>
                </Box>
              </Box>
            </BasicContentBox>
          </Grid>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default DataSecuritySkeleton;

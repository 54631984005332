import { Box, Skeleton } from '@mui/material';

import TableSkeleton from '@root/components/table/skeleton';
import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

function EventTableSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />

      <ContentBox paddingMode="content" marginBottom="15px">
        <Box
          // className="Borders RoundedCorners"
          marginTop="15px"
          marginLeft="15px"
          marginRight="15px">
          <CloudProviderBarSkeleton />
        </Box>
        <Box
          className="Borders RoundedCorners"
          marginLeft="15px"
          marginRight="15px"
          marginBottom="15px"
          marginTop="15px">
          <Skeleton height="40px" style={{ transform: 'none' }} />
        </Box>

        <TableSkeleton paddingLeft="15px" paddingRight="15px" />
      </ContentBox>
    </PageContent>
  );
}

export default EventTableSkleton;

import { Box, Skeleton, Grid } from '@mui/material';
// import { SmilyFaceEmoji } from '@root/assets/svg/SVGComponents';
// import Loader from '@root/components/common/Loader';
import TableSkeleton from '@root/components/table/skeleton';
import NoData from '@root/components/common/NoData';
// import ContentBox from '@root/components/v2/content/ContentBox';
import PageContainer from '@root/components/v2/layout/PageContainer';
import PageContent from '@root/components/v2/layout/PageContent';
// import PageHeader from '@root/components/v2/layout/PageHeader';
import { axiosPost } from '@root/services/axios/axiosPost';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import EventsDropdown from '../events/EventsDropdown';
import Fields from './Fields';

import Permission from './Permission';
import './index.scss';
import ContentBox from '@root/components/v2/content/ContentBox';
// import PageHeader from '@root/components/common/PageHeader';
import searchIcon from '@assets/svg/search.svg'
const Profile = () => {
  const [selectedPolicyGroup, setSelectedPolicyGroup] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  console.log(setSearchTerm);

  const { data: permissionsData, isFetching: isFetchingPermissionsData } = useQuery(['get_user_permissions'], () =>
    axiosPost('user-page-permission', '', true),
  );

  const profileData = useMemo(() => {
    let _data = {
      userID: '',
      email: '',
      name: '',
      permissionGroups: [],
      permissions: [],
    };

    if (!permissionsData?.data?.data) return _data;
    const data = permissionsData?.data?.data;

    _data = {
      userID: data.user_id,
      email: data.email,
      name: data.name,
      permissionGroups: Object.keys(data.policy_data[0]),
      permissions: data.policy_data,
    };

    return _data;
  }, [permissionsData]);

  const permissions = useMemo(() => {
    const _permission =
      profileData?.permissions?.find(permission => Object.keys(permission)[0] === selectedPolicyGroup) || {};

    const allPermissions = Object.values(_permission)[0] || [];

    return allPermissions.filter(premission => premission.page_item.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [profileData?.permissions, searchTerm, selectedPolicyGroup]);

  useEffect(() => {
    if (profileData) setSelectedPolicyGroup(profileData?.permissionGroups?.[0] || '');
  }, [profileData]);

  return (
    <PageContainer className="Profile__PageContainer">
      {/* <PageHeader title="Profile" breadcrumb /> */}

      <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '0.5rem' }}>
          <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#333',marginLeft: '0.7rem' }}>Profile</span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #ddd',
            paddingBottom: '0.5rem',
            width: '100%',
          }}>
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#333',
              // paddingRight: '1rem',
              marginLeft:'1.3rem',
              // borderBottom: '3px solid #3b82f6',
              // paddingBottom: '0.2rem',
            }}>
            General
          </span>
        </div>
      </Box>

      <PageContent>
        {isFetchingPermissionsData ? (
          <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="flex-start">
            <Box display="flex" flexDirection="column">
              <Box marginBottom="20px">
                <Skeleton variant="circular" height="130px" width="130px" />
              </Box>
              <Skeleton variant="rectangular" width="140px" height="30px" />
            </Box>
            <Box
              margin="35px 0"
              height="100%"
              width="40%"
              display="flex"
              flexDirection="column"
              justifyContent="flex-start">
              {Array.from(new Array(3)).map((i, index) => (
                <Box key={index} height="50px" display="flex" justifyContent="space-between">
                  <Skeleton height="50px" width="75px" />
                  <Skeleton height="50px" width="450px" />
                </Box>
              ))}
            </Box>
            <Box>
              <Skeleton height="40px" width="40%" />
            </Box>
            <Box
              marginTop="10px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              margin="px 0"
              width="41%">
              <TableSkeleton COLUMNS_COUNT={4} ROWS_COUNT={11} hideFooter={true} noBorder={true} />
            </Box>
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              {/* Avatar (col={4}) */}
              <>
                <Grid item xs={12}>
                  <ContentBox
                    title="Global"
                    titleSize="medium"
                    titleContainerStyle={{
                      fontSize: '16px !important',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      borderBottom: '1px solid #CBD5E0',
                      margin: '0px',
                      color: 'var(--keyinsight-text-color)',
                      background: '#F7FAFC',
                    }}
                    containerClassName="content-box"
                    paddingMode="content">
                    <div className="profile-container">
                      {/* Left Side: Avatar and Button */}
                      <div className="profile-avatar">
                        <div className="Profile__Avatar">{profileData.name.charAt(0).toUpperCase()}</div>
                        {/* <button className="SelectAvatar__Button">
                          <SmilyFaceEmoji />
                          <span>Select Avatar</span>
                        </button> */}
                      </div>

                      {/* Right Side: Input Fields */}
                      <div className="profile-fields">
                        <Fields name="Email" value={profileData.email} showCopy={true} disabled={false} />
                        <Fields name="Full Name" value={profileData.name} />
                        <Fields name="User ID" value={profileData.userID} showCopy={true} disabled={false} />
                      </div>
                    </div>
                  </ContentBox>
                </Grid>

                <Grid item xs={12}>
                  <ContentBox
                    title="Your App Permission"
                    titleSize="medium"
                    titleContainerStyle={{
                      // border: "0px",
                      fontSize: '16px !important',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      borderBottom: '1px solid #CBD5E0',

                      margin: '0px',
                      color: 'var(--keyinsight-text-color)',
                      background: '#F7FAFC',
                      // backgroundColor: 'var(--keyinsight-box-header-background)',
                    }}
                    containerClassName={'border-box-no-pad'}
                    paddingMode="content">
                    <div className="ProfilePermissions__Container">
                      <div className="ProfilePermissions__FiltersContainer">
                      <img
                src={searchIcon}
                style={{
                  width: '16px',
                 
                }}
                alt="Search Icon"
              />
                        <input
                          className="ProfilePermissions__FilterInput"
                          onChange={e => setSearchTerm(e.target.value)}
                          value={searchTerm}
                          placeholder="Search permissions..."
                        />
                        <EventsDropdown
                          eventType={selectedPolicyGroup}
                          setEventType={setSelectedPolicyGroup}
                          options={profileData.permissionGroups}
                        />
                      </div>
                      <div className="CustomScrollbar" style={{ maxHeight: '428px' ,overflow:'auto'}}>
                        <div className="ProfilePermissions__PermissionsBox">
                          <div className="ProfilePermissions__Header">
                            <Box flexGrow={1}>Permissions</Box>
                            <div style={{ position: 'relative', right: '53px' }}>Read</div>
                            <div>Write</div>
                          </div>
                          {_.isEmpty(permissions) ? (
                            <NoData message={'No Permissions'} height={'150px'} />
                          ) : (
                            permissions.map((permission, idx) => (
                              <Permission
                                name={permission.page_item}
                                p1={permission.read}
                                p2={permission.read_write_permission}
                                key={idx}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </ContentBox>
                </Grid>
              </>
            </Grid>

            <Box flexGrow={1} />
            {/* <div className="Profile__ActionButtons">
                <button className="Profile__ResetButton">Reset</button>
                <button className="Profile__SaveButton">Save</button>
              </div> */}
          </>
        )}
        {/* </ContentBox> */}
      </PageContent>
    </PageContainer>
  );
};

export default Profile;

import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import PageContent from '@root/components/v2/layout/PageContent';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';


function InventoryOverviewSkeleton() {
  
  return (
    <PageContent>
      <PageHeaderSkeleton isRegionFilter={true} />
      <CloudProviderBarSkeleton />

        <Box height="100%">

        
        <Box display="flex" flexDirection="row" gap="15px" height = "406px" marginBottom="15px" marginTop="5px">
          {['Cloud Provider', 'Top Service Categories', 'Resource Trend', 'Health Score Breakdown'].map(title => (
            <ContentBox
              key={title}
              title={title}
              paddingMode="content"
              style={{ flexGrow: 1, width: 'calc(24% - 15px)' }}>
              {/* Set a fixed width for each ContentBox */}

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
               
                padding="15px"
                paddingTop="35px">
                <Box width="100%" display="flex" justifyContent="center" >
                  {title === 'Top Service Categories' || title === 'Resource Trend' ? (
                    <Skeleton variant="rectangular" height="160px" sx={{ width: '100%', flexGrow: 1 }} />
                  ) : (
                    <Skeleton variant="circular" width="160px" height="160px" />
                  )}
                </Box>
                <Box flexGrow={0.4} height="10px" width="100%" />

                <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" marginBottom="5px">
                  <Skeleton width="100px" />
                  <Skeleton width="100px" />
                </Box>
                <Grid sx={{ width: '100%'}}>
                  {Array.from(new Array(title === 'Cloud Provider' ? 3 : 4)).map((i, index) => (
                    <Box
                      key={i}
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      gap={title === 'Cloud Provider' ? '15px' : '10px'}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        marginTop={index === 0 ? '0px' : title === 'Cloud Provider' ? '20px' : '8px'}>
                        <Skeleton width="80px" />
                        {title === 'Resource Trend' && <Skeleton width="80px" />}
                        <Skeleton width="80px" />
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Box>
            </ContentBox>
          ))}
        </Box>
        <Box display="flex" flexDirection="row" gap="15px" height = "406px">
          {['Resource Compliance', 'Identity Risk', 'Internet Exposed Resource', 'Alerts by CQ Shield Priority'].map(title => (
            <ContentBox
              key={title}
              title={title}
              paddingMode="content"
              style={{ flexGrow: 1, width: 'calc(25% - 15px)' }}>
              {/* Set a fixed width for each ContentBox */}

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                
                padding="15px"
                paddingTop={title=="Internet Exposed Resource" ? "5px": "35px"}>
                {title === 'Internet Exposed Resource' ? (
                  <>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      >
                      <Skeleton width="100px" />
                      <Skeleton width="100px" />
                    </Box>
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%',
                        // pt: '15px',
                      }}>
                      {Array.from(new Array(4)).map(i => (
                        <Grid
                          key={i}
                          sx={{ border: '1px solid #cbd5e0', padding: '8px 10px', borderRadius: '5px', width: '100%' }}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            marginBottom="10px">
                            <Skeleton width="100px" />
                            <Skeleton width="100px" />
                          </Box>
                          <Box width="100%">
                            <Skeleton width="100%" />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box width="100%" display="flex" justifyContent="center" >
                      {title === 'Top Service Categories' || title === 'Resource Trend' ? (
                        <Skeleton variant="rectangular" height="160px" sx={{ width: '100%', flexGrow: 1 }} />
                      ) : (
                        <Skeleton variant="circular" width="160px" height="160px" />
                      )}
                    </Box>
                    <Box flexGrow={0.4} height="10px" width="100%" />
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginBottom="5px">
                      <Skeleton width="100px" />
                      <Skeleton width="100px" />
                    </Box>
                    <Grid sx={{ width: '100%' }}>
                      {Array.from(new Array(title === 'Resource Compliance' ? 3 : 4)).map((i, index) => (
                        <Box
                          key={i}
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          gap={title === 'Cloud Provider' ? '15px' : '10px'}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            marginTop={index === 0 ? '0px' : title === 'Resource Compliance' ? '20px' : '8px'}>
                            <Skeleton width="80px" />
                            {title === 'Resource Trend' && <Skeleton width="80px" />}
                            <Skeleton width="80px" />
                          </Box>
                        </Box>
                      ))}
                    </Grid>
                  </>
                )}
              </Box>
            </ContentBox>
          ))}
        </Box>
        </Box>
    </PageContent>
  );
}

export default InventoryOverviewSkeleton;

import { InformationIcon } from '@root/assets/svg/SVGComponents';
import './style.scss';
import React, { ReactNode } from 'react';
import Tooltip from '../../ui-elements/Tooltip';

interface BasicContentBoxProps {
  children: ReactNode;
  headerContent: ReactNode;
  title?: string | ReactNode;
  contentClassName?: string;
  subtitle?: string;
  rightSideContent?: ReactNode;
  titleContainerStyles?: any;
  props?: any;
  titleTextStyles?: any;
  innerStyles?: React.CSSProperties;
}

const BasicContentBox = ({
  children,
  title,
  contentClassName,
  subtitle,
  rightSideContent,
  innerStyles,
  titleTextStyles,
  titleContainerStyles,
  headerContent,
  ...props
}: BasicContentBoxProps) => {
  return (
    <div className="BasicContentBox__Container" {...((title || headerContent) && { ...props })}>
      {(title || headerContent) && (
        <div className="BasicContentBox__Title" style={titleContainerStyles}>
          <div className="BasicContentBox__TitleInner content-box-heading" style={titleTextStyles}>
            {title || headerContent}

            {!!subtitle && (
              <Tooltip title={subtitle}>
                <div>
                  <InformationIcon />
                </div>
              </Tooltip>
            )}
          </div>
          <div className="BasicContentBox__RightSideContent">{rightSideContent}</div>
        </div>
      )}
      <div className={`BasicContentBox__Content CustomScrollbar ${contentClassName}`} style={innerStyles}>
        {children}
      </div>
    </div>
  );
};

export default BasicContentBox;

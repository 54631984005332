import { Drawer, Box, } from '@mui/material';

const SideOverlay = ({
  open,
  onClose,
  width = 300,
  height = '100%',
  anchor = 'right',
  children
}) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: width,
          height: height,
          maxHeight: '100%',
          position: 'fixed',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        },
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideOverlay;

import { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import usePubSub from './hooks/usePubSub';
import { updateNotificationBanner } from '@components/notification/actions';
import { ADD_ONGOING_REMEDIATION, REMOVE_ONGOING_REMEDIATIONS } from './constants/actionTypes';

function RemediationHandler() {
  const dispatch = useDispatch();
  const [remData, setRemData] = useState('');

  const ongoingRemediations = useSelector(store => {
    return store.inProgress.remediations;
  });
  const ongoingRemediationsRef = useRef(ongoingRemediations);
  ongoingRemediationsRef.current = ongoingRemediations;
  usePubSub(
    'one_click_remediation',
    useCallback(data => {
      setRemData(data);
    }, []),
  );
  console.log('remData', remData);
  useEffect(() => {
    (async () => {
      try {
        if (!ongoingRemediationsRef?.current || !ongoingRemediationsRef?.current?.length) return;
        let removedRemediationIds = [];
        if (
          remData.remediation_id &&
          ongoingRemediationsRef.current.find(remediation => remediation.remediation_id === remData.remediation_id)
        ) {
          if (remData.success) {
            // Remediation completed or failed due to exceeding REMEDIATION_EXPIRY_SECONDS seconds
            updateNotificationBanner(dispatch, {
              status: 'success',
              msg: remData?.message,
              id: remData?.remediation_id,
            });

            removedRemediationIds.push(remData?.remediation_id);
          } else {
            updateNotificationBanner(dispatch, {
              status: 'error',
              msg: remData?.message,
              id: remData?.remediation_id,
            });
            removedRemediationIds.push(remData?.remediation_id);
          }
        }

        if (removedRemediationIds.length) {
          dispatch({
            type: REMOVE_ONGOING_REMEDIATIONS,
            payload: removedRemediationIds,
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [dispatch, remData?.success, remData?.message, remData?.remediation_id]);

  usePubSub(
    'remediation_started',
    useCallback(
      data => {
        dispatch({ type: ADD_ONGOING_REMEDIATION, payload: data });

        updateNotificationBanner(dispatch, {
          status: 'inProgress',
          msg: `Remediation in progress`,
          id: data.remediation_id,
        });
      },
      [dispatch],
    ),
  );

  return null;
}

export default RemediationHandler;
